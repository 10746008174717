/* Remove all table borders */
.custom-table {
  border-collapse: collapse;
  border: none;
}

.custom-table th,
.custom-table td {
  border: none;
}

.custom-table tr {
  border-bottom: 1px solid #dee2e6;
}

.custom-table tbody tr:hover {
  background-color: #7d7474 !important;
}
