@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;

}

body {
  background: #e4f1f9;
  font-family: "Poppins", sans-serif !important;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}
